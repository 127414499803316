<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">NEW DEPOSIT SLIP FOR RECEIVABLE</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="multi-col-validation">
        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="date_deposited"
              label="Date Deposited"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="date"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="time_deposited"
              label="Time Deposited"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              v-model="bank_id"
              class="mx-2"
              dense
              outlined
              label="Bank"
              :items="bank_items"
              item-value="id"
              item-text="bank_code"
              :rules="rules.combobox_rule"
              @change="selected_bank"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="account_no"
              label="Account #"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              readonly
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-text-field
              class="mx-2"
              v-model="amount"
              label="Amount"
              dense
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
              min="0"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md6>
            <v-checkbox
              class="mx-2"
              v-model="is_no_cash"
              label="Is No Cash"
              v-if="position==='ADMIN'"
            ></v-checkbox>
          </v-flex>
          <v-flex xs12 md12 class="mb-3">
            <v-select
              v-model="category"
              class="mx-2"
              dense
              outlined
              label="Category"
              :rules="rules.combobox_rule"
              :items="['CA','LOAN','AUTO LOAN','OTHER RECEIVABLE','OFFICE RENTAL SEC DEPOSIT','MOTOR FINANCE','BOD CHARGE']"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-col cols="12" v-show="alert">
          <v-alert
            color="warning"
            text
            class="mb-0"

          >
            <div class="d-flex align-start">
              <v-icon color="warning">
                {{ icons.mdiAlertOutline }}
              </v-icon>

              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  {{ alert_message }}
                </p>
              </div>
            </div>
          </v-alert>
        </v-col>
        <v-col cols="12" md="12">
          <v-btn
            class="w-full"
            color="primary"
            @click="save_request"
            v-if="!saving_data"
          >
            Save Request
          </v-btn>
          <v-progress-circular
            :size=50
            :width="5"
            color="primary"
            indeterminate
            v-else
          ></v-progress-circular>
        </v-col>
      </v-form>
      <v-simple-table dense class="mt-2">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-uppercase">
              ID
            </th>
            <th class="text-uppercase">
              Date
            </th>
            <th class="text-uppercase">
              Time
            </th>
            <th class="text-uppercase">
              Bank
            </th>
            <th class="text-uppercase">
              Amount
            </th>
            <th class="text-uppercase">
              Category
            </th>
            <th class="text-uppercase">
              Status
            </th>
            <th class="text-uppercase">
              Action
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in data_items"
            :key="item.id"
          >
            <td>
              {{ item.id }}
            </td>
            <td>
              {{ item.date }}
            </td>
            <td>
              {{ item.time }}
            </td>
            <td>
              {{ item.bank.bank_code }}
            </td>
            <td>
              {{ formatPrice(item.amount) }}
            </td>
            <td>
              {{ item.category }}
            </td>
            <td>
              {{ item.is_no_cash === 1 ? 'NO CASH' : 'CASH' }}
            </td>
            <td class="text-center">
              <v-icon
                v-if="item.auto_loan_payment.length===0&&item.cash_advance_payment.length===0&&item.loan_application_payment.length===0
                    &&item.other_receivable_payment.length===0&&item.motor_finance_payment.length===0&&item.services_payment.length===0"
                class="mr-2"
                color="error"
                @click="delete_dep(item.id)"
              >
                {{ icons.mdiDelete }}
              </v-icon>
              <v-progress-circular
                color="info"
                indeterminate
                v-if="is_deleting_request"
              ></v-progress-circular>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
import {mdiCheck, mdiClose} from "@mdi/js";
import moment from "moment";
import {mapActions, mapGetters} from "vuex";
import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
import {
  mdiPencilOutline,
  mdiAccountSearchOutline,
  mdiDelete,
  mdiThumbDown,
  mdiThumbUp,
  mdiPencil,
  mdiGoogleCirclesCommunities,
  mdiAccountOutline,
  mdiPrinter,
  mdiCashCheck,
} from '@mdi/js'

const initialState = () => {
  return {
    data_items: [],
    bank_items: [],
    is_deleting_request: false,
    saving_data: false,
    alert: false,
    alert_message: '',
    is_no_cash: false,
    category: '',
    amount: '',
    account_no: '',
    time_deposited: '',
    date_deposited: '',
    bank_id: '',
    icons: {
      mdiPencilOutline,
      mdiAccountSearchOutline,
      mdiDelete,
      mdiThumbDown,
      mdiThumbUp,
      mdiPencil,
      mdiPrinter,
      mdiCashCheck,
    },
  }
}
export default {
  components: {
    snackBarDialog,
  },
  setup() {
    return {
      icons: {
        mdiCheck,
        mdiClose,
      },
    }
  },
  data() {
    return initialState()
  },
  mounted() {
    this.initialize_data()
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    ...mapGetters('authentication', ['position']),
  },
  methods: {
    ...mapActions('system_data', ['change_snackbar']),
    ...mapActions('deposit_slip_receivable', ['initialize_deposit_slip_receivable', 'save_deposit_slip_receivable', 'delete_receivable_deposit']),
    delete_dep(id) {
      const data = new FormData()
      data.append('id', id);
      this.delete_receivable_deposit(data)
        .then(response => {
          this.saving_data = false
          var color = 'success'
          if (response.status === 201) {
            color = 'error'
          }
          this.change_snackbar({
            show: true,
            color: color,
            text: response.data,
          })
          Object.assign(this.$data, initialState())
          this.initialize_data()
        })
        .catch(error => {
          console.log(error)
        });
    },
    initialize_data() {
      this.initialize_deposit_slip_receivable()
        .then(response => {
          this.bank_items = response.data[0].bank
          this.data_items = response.data[0].data
        })
        .catch(error => {
          console.log(error)
        })
    },
    date_format(value) {
      return moment(value)
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    save_request() {
      this.saving_data = true
      this.alert = false
      if (this.$refs.form.validate()) {
        const data = new FormData()
        data.append('bank_id', this.bank_id);
        data.append('category', this.category);
        data.append('date', this.date_deposited);
        data.append('time', this.time_deposited);
        data.append('amount', this.amount);
        data.append('is_no_cash', this.is_no_cash);
        this.save_deposit_slip_receivable(data)
          .then(response => {
            this.saving_data = false
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            Object.assign(this.$data, initialState())
            this.initialize_data()
          })
          .catch(error => {
            console.log(error)
          });
      } else {
        this.alert = true
        this.alert_message = 'Please Fill up the field/s'
        this.saving_data = false
      }
    },
    selected_bank(value) {
      var index = this.bank_items.map(function (x) {
        return x.id;
      }).indexOf(value)
      if (index != -1) {
        this.account_no = this.bank_items[index].account_no
      }
    },
  },
}
</script>
